<template>
  <div>
    <custom-toolbar
      :title="$t('Operators')"
      :total="$store.getters['operator/total']"
      @refresh="getItems"
    />
    <v-card class="mb-6 mt-6">
      <div class="m-2">
        <v-row class="ml-5 mr-5">
          <v-col
            cols="12"
            md="12"
          >
            <v-text-field
              v-model="$store.state.operator.field.keyword"
              label="Rechercher globalement"
              outlined
              dense
              placeholder="Rechercher globalement"
              hide-details
              clearable
              @input="getItemsByFields"
            ></v-text-field>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <v-card>
      <v-data-table
        :loading="isDialogVisible"
        loading-text="Chargement.."
        :headers="headers"
        :items="$store.getters['operator/operators']"
        :items-per-page="itemsPerPage"
        :page.sync="currentPage"
        dense
        fixed-header
        height="500"
        hide-default-footer
        @page-count="currentPage = $event"
      >
        <template #[`item.label`]="{item}">
          <div class="d-flex align-center">
            <v-avatar
              :color="item.logo_path ? '' : 'primary'"
              :class="item.logo_path ? '' : 'v-avatar-light-bg primary--text'"
              size="32"
            >
              <v-img
                v-if="item.logo_path"
                :src="`${item.logo_path}`"
              ></v-img>
              <span v-else-if="item.label">{{ item.label ? item.label.slice(0,2).toUpperCase() : null }}</span>
              <v-img
                v-else
                :src="require('@/assets/images/avatars/1.png')"
              />
            </v-avatar>
            <div class="d-flex flex-column ms-3">
              <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.label }}</span>
              <small>{{ item.code }}</small>
            </div>
          </div>
        </template>

        <template #[`item.created_at`]="{item}">
          {{ formattingDate(`${item.created_at}`) }}
        </template>

        <template #[`item.updated_at`]="{item}">
          {{ formattingDate(`${item.updated_at}`) }}
        </template>

        <template #[`item.is_active`]="{item}">
          <v-chip
            small
            :color="item.is_active ? 'primary' : 'error'"
            :class="`${item.is_active ? 'primary' : 'error'}--text`"
            class="v-chip-light-bg"
          >
            {{ item.is_active === true ? 'OUI' : 'NON' }}
          </v-chip>
        </template>

        <!-- action -->
        <template #[`item.actions`]="{ item }">
          <buttons-group
            :item="item"
            :lock="false"
            :status="false"
            :remove="false"
            :activate="true"
            @view="openItem(item)"
            @activate="deleteItem(item)"
            @edit="openForm(item)"
          />
        </template>
      </v-data-table>

      <v-card-text class="pt-2">
        <v-row>
          <v-col
            lg="2"
            cols="3"
          >
            <v-text-field
              v-model="$store.state.operator.field.paginate"
              label="Lignes par page:"
              type="number"
              min="-1"
              max="15"
              hide-details
              @input="getItemsPerPage"
            ></v-text-field>
          </v-col>

          <v-col
            lg="10"
            cols="9"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="currentPage"
              total-visible="6"
              :length="lastPage"
              @input="getItems"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <operator-form-dialog
      :dialog="dialog"
      :operator="operator"
      @clickout="closeDialog"
      @submitted="loading"
    />
    <confirm-dialog
      :message="confirmMessage"
      :dialog-delete="dialogDelete"
      @clickout="closeDelete"
      @submitted="activateItem"
    />
  </div>
</template>

<script>
import LoaderDialog from '@core/components/dialogs/LoaderDialog'
import { mapGetters, mapState } from 'vuex'
import {
  mdiAccountLock,
  mdiDeleteOutline,
  mdiDotsVertical, mdiEyeOutline, mdiLock, mdiLockOpen,
  mdiPencilOutline, mdiPlus, mdiRefresh,
  mdiSquareEditOutline,
} from '@mdi/js'
import { formatDate } from '@core/date/dateFunctions'
import OperatorFormDialog from '@core/components/operator/OperatorFormDialog'
import ConfirmDialog from '@core/components/dialogs/ConfirmDialog'
import { notify } from '@core/dynamicComponents/dynamicComponents'
import CustomToolbar from '@core/components/toolbar/CustomToolbar'
import ButtonsGroup from '@core/components/buttons/ButtonsGroup'
import { onMounted, ref } from '@vue/composition-api/dist/vue-composition-api'
import router from '@/router'
import store from '@/store'

export default {
  name: 'Operator',

  components: {
    ButtonsGroup, CustomToolbar, ConfirmDialog, OperatorFormDialog, LoaderDialog,
  },

  setup() {
    const currentPage = ref(null)
    const lastPage = ref(null)
    const dialogDelete = ref(false)
    const isDialogVisible = ref(false)
    const dialog = ref(false)
    const confirmMessage = ref('Voulez-vous désactiver cet opérateur?')
    const operatorForm = {
      id: null,
      label: null,
      key: null,
      value: null,
    }
    const itemsPerPage = ref(null)
    const operator = ref({})

    const formattingDate = date => formatDate(date)

    const closeDelete = () => {
      dialogDelete.value = false
    }

    const deleteItemConfirm = () => {
      closeDelete()
    }

    const closeDialog = () => {
      dialog.value = false
    }

    const deleteItem = item => {
      confirmMessage.value = item.is_active ? "Voulez-vous activer l'opérateur?" : "Voulez-vous désactiver l'opérateur?"
      operator.value = item
      dialogDelete.value = true
    }

    const activateItem = () => {
      store.dispatch('operator/activateOperator', operator.value).then(response => {
        notify(true, true, response.message)
      }).catch(error => {
        notify(true, true, error.response.data.message)
        console.log(error)
      })
      closeDelete()
    }

    const getItems = (page = 1) => {
      isDialogVisible.value = true
      store.dispatch('operator/getOperatorsList', { page, field: store.state.operator.field }).then(() => {
        currentPage.value = store.getters['operator/current_page']
        lastPage.value = store.getters['operator/last_page']
        isDialogVisible.value = false
      }).catch(error => {
      })
    }

    // eslint-disable-next-line camelcase
    const getItemsPerPage = per_page => {
      // eslint-disable-next-line camelcase
      if (per_page !== null) {
        isDialogVisible.value = true
        // eslint-disable-next-line camelcase
        store.state.operator.field.paginate = per_page
        itemsPerPage.value = parseInt(per_page, 10)
        store.dispatch('operator/getOperatorsList', {
          page: store.state.operator.field.paginate,
          field: store.state.operator.field,
        }).then(() => {
          currentPage.value = store.getters['operator/current_page']
          lastPage.value = store.getters['operator/last_page']
          isDialogVisible.value = false
        })
      }
    }

    const openItem = item => {
      router.push({ name: 'showoperator', params: { id: item.id } })
    }

    const getItemsByFields = () => {
      isDialogVisible.value = true
      store.dispatch('operator/getOperatorsList', {
        page: 1,
        field: store.state.operator.field,
      }).then(() => {
        currentPage.value = store.getters['operator/current_page']
        lastPage.value = store.getters['operator/last_page']
        isDialogVisible.value = false
      })
    }

    const openForm = (item) => {
      console.log(item)
      operator.value = item
      console.log(operator.value)
      dialog.value = true
    }

    const loading = () => {
      isDialogVisible.value = !isDialogVisible.value
    }

    onMounted(() => {
      getItems(store.getters['operator/current_page'] ? store.getters['operator/current_page'] : null)
      //operator.value = operatorForm
    })

    return {
      itemsPerPage,
      deleteItem,
      closeDialog,
      activateItem,
      getItems,
      getItemsPerPage,
      openItem,
      getItemsByFields,
      openForm,
      loading,
      closeDelete,
      operator,
      formattingDate,
      operatorForm,
      dialog,
      isDialogVisible,
      dialogDelete,
      confirmMessage,
      currentPage,
      lastPage,
      headers: [
        { text: 'LIBELLE', value: 'label' },
        { text: 'ACTIF?', value: 'is_active' },
        { text: 'DATE DE CREATION', value: 'created_at' },
        { text: 'DATE DE MISE A JOUR', value: 'updated_at' },
        { text: 'ACTIONS', value: 'actions' },
      ],
      itemsPerPage: parseInt(store.state.operator.field.paginate),

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiAccountLock,
        mdiLockOpen,
        mdiPlus,
        mdiEyeOutline,
        mdiRefresh,
        mdiLock,
      },
    }
  },
}
</script>

<style scoped>

</style>
